<template>
  <v-dialog v-model="googleIdDialog" :key="item._id" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        icon
        :color="mapUrl && mapUrl.length > 0 ? 'green' : 'grey lighten-1'"
      >
        <v-icon>mdi-google-maps</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3" no-gutters>
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h3 class="title text-start text-break">Google Maps ID</h3>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="googleIdDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          class="mx-0"
          align="center"
          justify="center"
          justify-md="end"
          no-gutters
        >
          <v-col cols="12" class="px-0 py-0">
            <v-row class="mx-0" no-gutters>
              <span class="text-left secondary--text subtitle-1 mb-4">
                Assign the Google Maps ID for <b>{{ item.name }}</b
                >, by typing in the country name on the field below.
              </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0">
            <v-row class="mx-0" no-gutters>
              <v-text-field
                ref="autocomplete"
                v-model="countryName"
                label="Type the country name here"
                placeholder
                outlined
                :error-messages="errorCountryName"
                @focus="errorCountryName = null"
                @change="setLocation"
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
          no-gutters
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="googleIdDialog = false"
              tile
              outlined
              depressed
              bottom
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8 mx-0"
              @click="submitFn"
              color="secondary"
              tile
              depressed
              bottom
              right
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// config
import Config from "@/services/config";

// services
import countryServices from "@/services/country.service";


export default {
  name: "GoogleIDCountryModal",
  props: {
    item: Object,
    mapUrl: String || null,
  },
  data() {
    return {
      googleIdDialog: false,
      search: "",
      countryName: null,
      long_name: null,
      short_name: null,
      placeId: null,
      googleMapURL: null,
      cityName4BackEnd: null,
      formattedAddress: null,
      lat: null,
      lng: null,
      googleAutocomplete: null,
      errorCountryName: null,
      staticErrorMessages: {
        countryName: {
          isEmpty: "Country name should not be empty",
          isInvalid: "Please select a valid country from the list",
        },
      },
    };
  },
  watch: {
    googleIdDialog(val) {
      if (val === true) {
        setTimeout(() => {
          this.checkAndAttachAutocompleteScript(() => {
            let element = this.$refs.autocomplete.$el;

            element = element.querySelector("input");
            this.googleAutocomplete =
              new window.google.maps.places.Autocomplete(element, {
                types: ["(regions)"],
              });
          });
        }, 500);
      }
    },
    countryName() {
      this.errorCountryName = null;
    },
  },
  methods: {
    checkAndAttachAutocompleteScript(callback) {
      let scriptId = "autocomplete-api-script";
      let scriptAlreadyAttached = !!document.getElementById(scriptId);
      if (scriptAlreadyAttached) {
        if (window.google)
          // Script attached but may not finished loading; so check for 'google' object.
          callback();
      } else {
        window.autocompleteApiInitialized = callback;
        const APIKEY = Config.gMapAPIKey;
        let script = document.createElement("script");
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places&language=en&callback=autocompleteApiInitialized&v=weekly`;
        document.body.appendChild(script);
      }
      return scriptAlreadyAttached;
    },
    setLocation() {
      this.googleAutocomplete.addListener("place_changed", () => {
        let place = this.googleAutocomplete.getPlace();
        if (place.types[0] == "country") {
          let address_components = place.address_components.find((address) => {
            if (address.types.includes("country")) {
              return address;
            }
          });
          console.log(address_components);
          this.long_name = address_components.long_name;
          this.short_name = address_components.short_name;
          this.placeId = place.place_id;
          this.googleMapURL = place.url;
          this.countryName = place.formatted_address;
          this.formattedAddress = place.formatted_address;
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
        } else {
          // show error message
          this.errorCountryName =
            this.staticErrorMessages.countryName.isInvalid;
        }
      });
    },
    submitFn() {
      if ((this.countryName, this.placeId)) {
        let payload = {
          GoogleMapURL: this.googleMapURL,
          GooglePlaceId: this.placeId,
          ShortName: this.short_name,
        };
        console.log(payload);
        countryServices.setCountryVerify(payload).then((res) => {
          if (res.data.msg == "OK") {
            this.googleIdDialog = false;
            let payload2 = {
              snackBarText: `Country Details Updated`,
              snackBarColor: "success",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$emit("initFN");
          }
        });
      } else {
        if (!this.countryName) {
          this.errorCountryName = this.staticErrorMessages.countryName.isEmpty;
        }
        if (!this.placeId) {
          this.errorCountryName = this.staticErrorMessages.countryName.isEmpty;
        }
      }
    },
  },
};
</script>
<style scoped>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>