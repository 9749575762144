<template>
  <v-dialog
    v-model="topicSubTopicDialog"
    :key="item._id"
    max-width="600"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <v-icon :color="(item.achievedSDGs && item.achievedSDGs.length>0)  ? 'blue' : 'grey'"
        >mdi-leaf
        </v-icon
        >
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-3 px-2 px-sm-6" style="margin-bottom: 15px">
        <v-row>
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="headline text-left mt-4">
              Update Sust.Initiatives: {{ item.name }}
            </h2>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="topicSubTopicDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0 mt-5">
          <v-row>
            <v-col cols="12" md="9" class="py-2">
              <h3 class="text-left mt-4 mb-4">
                Is there an active Sustainability Commitment?
              </h3>
 
                </v-col>
                <v-col cols="12" md="1" class="py-6">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <font-awesome-icon
                      style="margin-left: 10px;"
                      class="info-icon"
                      :icon="['fas', 'info-circle']"
                      color="secondary"
                      size="L"
                      v-on="on"
                    />
                  </template>
                  <div style="max-width: 25vw">
                  <span class="info-tooltip-text"
                  
                    > By clicking this button, you have to prove your 
                    destination’s sustainability commitment by answering the following questions.</span
                  >
                  </div>
                </v-tooltip>
                </v-col>
                <v-col cols="12" md="2" class="py-1">
                <v-switch
                
                  v-model="isToggleEnabled"
                  class="my-4"
                ></v-switch>

              </v-col>
              </v-row>
             <v-row class=" mt-3 ">
                <v-col cols="12" md="9" class="py-0">
                  <h3 class="text-left mt-2 mb-4">
                    Does your destination hold any sustainability certificates?
                  </h3>
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <!-- Big Toggle Switch -->
                  <v-switch v-model="isToggleEnableCertificate" :disabled="!isToggleEnabled"></v-switch>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-file-input label="Upload Sustainability Certificate" placeholder="Choose File" prepend-icon
                    append-icon="mdi-paperclip" clear-icon="mdi-close" v-model="file" @change="fileChanged"
                    ref="fileClearUpload" :key="fileUploadKey" truncate-length="14" clearable show-size outlined required
                    accept="application/pdf" hide-details="auto" :disabled="!isToggleEnableCertificate" ></v-file-input>
                </v-col>
              </v-row>
              <v-row style="margin-top: 50px">
             
             <v-col cols="12" md="6" class="py-0">
               <v-btn type="submit" class="px-12 mb-3 mx-n0" color="secondary" tile depressed bottom
                 :disabled="files.length < 1" @click="uploadDocs" :loading="uploadInProgress">
                 Upload
               </v-btn>
             </v-col>
             <v-col cols="12" md="12" class="py-0" style="margin-top: 5px">
               <div v-for="f in documentUrls" :key="f">
                 <h6 style="font-size: 12px">{{ decodeURIComponent(f.split('/')[f.split('/').length - 1]).replace(/[%()]/g, "")}}
                   <button @click="removeFile(f, 2)">
                     <v-icon color="red">mdi-delete</v-icon>
                   </button>
                 </h6>
               </div>
             </v-col>           
           </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-left mt-4 mb-4">
                Overall Ranking
              </h3>
              <v-text-field
                class="my-0"
                v-model="overall"
                label="Score of overall Ranking"
                outlined
                :disabled="!isToggleEnabled"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-left mt-4 mb-4">
                Overall SDG Index Score
              </h3>
              <v-text-field
                class="my-0"
                v-model="sdg"
                label="Score of SDG Index"
                outlined
                :disabled="!isToggleEnabled"
              ></v-text-field>
            </v-col>

          </v-row>
          <h3 class="text-left mt-4 mb-4">
            Achieved SDGs
          </h3>
          <v-container>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">01. No Poverty</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a1"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">02. Zero Hunger</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a2"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="8"><span class="w-100">03. Good Health & Well-being</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a3"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">04. Quality Education</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a4"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">05. Gender Equality</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a5"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">06. Clean Water & Sanitation</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a6"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">07. Affordable & Clean Energy</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a7"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">08. Decent Work & Economic Growth</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a8"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">09. Industry, Innovation & Infrastruture</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a9"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">10. Reduced Inequalities</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a10"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">11. Sustainable Cities & Communities</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a11"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">12. Responsible Consumption & Production</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a12"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">13. Climate Action</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a13"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>

                  <v-col cols="8"><span class="w-100">14. Life Below Water</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a14"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>

                  <v-col cols="8"><span class="w-100">15. Life on Land</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a15"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>
                  <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a16"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="8"><span class="w-100">17. Partnerships for the Goals</span></v-col>
                  <v-col style="margin-top:-20px;">
                    <v-checkbox primary  :disabled="!isToggleEnabled" v-model="a17"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ml-5">
                <v-row>
                  <!-- <v-col><img style="width:30px;height:auto;" src="https://conferli-storage.s3.us-east-2.amazonaws.com/sdg/inverted/E+Inverted+Icons_WEB-16.png"></v-col>
                  <v-col cols="8"><span class="w-100">16. Peace, Justice & Strong Institutions</span></v-col>
                  <v-col style="margin-top:-20px;"><v-checkbox  primary></v-checkbox ></v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
              d-flex
              flex-column-reverse flex-sm-row
              align-center
              justify-space-between
              mx-0
            "
        >
          <v-col
            cols="12"
            sm="3"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="topicSubTopicDialog = false"
              tile
              outlined
              depressed
              bottom
            >Cancel
            </v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8 mx-0"
              @click="submitFn"
              :loading="saving"
              :disabled="saving"
              color="secondary"
              tile
              depressed
              bottom
              right
            >Submit
            </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import countryApi from "../../../../services/country.service";

export default {
  name: "Topics",
  props: {
    item: Object,
  },
  data() {
    return {
      loading: true,
      isToggleEnabled: false,
      isToggleEnableCertificate: false,
      isDocumentUploaderEnabled: false,
      file: null,
      files: [],
      uploadInProgress: false,
      fileUploadKey: Number(new Date()),
      topicSubTopicDialog: false,
      overall: undefined,
      sdg: undefined,
      cityId: undefined,
      saving: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,
      a11: false,
      a12: false,
      a13: false,
      a14: false,
      a15: false,
      a16: false,
      a17: false,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        this.initFn();
      }
    },
    isToggleEnabled(newValue) {
      localStorage.setItem('isToggleEnabled', newValue);
    if (!newValue) { 
      this.a1 = false;
      this.a2 = false;
      this.a3 = false;
      this.a4 = false;
      this.a5 = false;
      this.a6 = false;
      this.a7 = false;
      this.a8 = false;
      this.a9 = false;
      this.a10 = false;
      this.a11 = false;
      this.a12 = false;
      this.a13 = false;
      this.a14 = false;
      this.a15 = false;
      this.a16 = false;
      this.a17 = false;
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;
      this.s4 = false;
      this.s5 = false;
      this.s6 = false;
      this.s7 = false;
      this.s8 = false;
      this.s9 = false;
      this.s10 = false;
      this.s11 = false;
      this.s12 = false;
      this.s13 = false;
      this.s14 = false;
      this.s15 = false;
      this.s16 = false;
      this.s17 = false;

      this.file = null;
      this.overall = null;
      this.profile = null;
      this.sdg = null;
      this.GDS_Index_Score = null;
      this.isToggleEnableCertificate = null;
     
    }
  },
  isToggleEnableCertificate(newValue) {
    localStorage.setItem('isToggleEnableCertificate', newValue);
      if (!newValue) {
        this.file = null;
      }
    },
  },
      mounted() {
    if (localStorage.getItem('isToggleEnabled') !== null) {
      this.isToggleEnabled = JSON.parse(localStorage.getItem('isToggleEnabled'));
    }
    if (localStorage.getItem('isToggleEnableCertificate') !== null) {
      this.isToggleEnableCertificate = JSON.parse(localStorage.getItem('isToggleEnableCertificate'));
    }},
  methods: {
    fileChanged(file) {
      if (file) {
        this.files.push(file);
      }
      this.file = null;
    },
    async removeFile(file, stage) {
      if (stage === 1) {
        for (let i = 0; i < this.files.length; i++) {
          if (file === this.files[i].name) {
            this.files.splice(this.files.indexOf(this.files[i]), 1);
            break
          }
        }
      } else {
        for (let i = 0; i < this.item.sustainabilityCertificates.length; i++) {
          if (file === this.item.sustainabilityCertificates[i]) {
            const url = this.item.sustainabilityCertificates[i];
            const payload = {
              url:url,
              profile:this.item.code
            }
            this.item.sustainabilityCertificates.splice(this.item.sustainabilityCertificates.indexOf(this.item.sustainabilityCertificates[i]), 1);
            countryApi.deleteSustainabilityCertificate(payload);
            this.initFn()
            this.$emit("initFN");
            break
          }
        }
      }
    },
    uploadDocs(e) {
      e.preventDefault();
      this.uploadInProgress = true;
      var formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append(`file_${i}`, this.files[i]);
      }
      formData.append("profile",this.item.code);
      countryApi.uploadSustainabilityCertificate(formData).then((res) => {
        this.documentUrls = [];
        for (let i = 0; i < res.data.content.length; i++) {
          try {
            this.documentUrls.push(res.data.content[i]);
          } catch (e) {
            this.documentUrls = [];
            this.documentUrls.push(res.data.content[i]);
          }
        }
        let payload = {
          snackBarText: "Refresh your profile to collect new sustainability certificate.",
          snackBarColor: "success"
        };
        this.$store.dispatch("setSnackbarMessage", payload);
        this.uploadInProgress = false;
        this.file = null;
        this.files = [];
        this.$emit('documentsUploaded', this.documentUrls);
        this.$emit("initFN");
        this.supportingDocEditDialog = false;
      });
    },
    async initFn() {
      console.log("<---------")
      console.log(this.item)
      this.documentUrls = this.item.sustainabilityCertificates;
      this.isToggleEnabled = this.item.isToggleEnabled;
      this.isToggleEnableCertificate = this.item.isToggleEnableCertificate;
      this.overall = this.item.overallRanking;
      this.sdg = this.item.overallSDGScore;
      this.a1 = this.item.achievedSDGs.includes(1);
      this.a2 = this.item.achievedSDGs.includes(2);
      this.a3 = this.item.achievedSDGs.includes(3);
      this.a4 = this.item.achievedSDGs.includes(4);
      this.a5 = this.item.achievedSDGs.includes(5);
      this.a6 = this.item.achievedSDGs.includes(6);
      this.a7 = this.item.achievedSDGs.includes(7);
      this.a8 = this.item.achievedSDGs.includes(8);
      this.a9 = this.item.achievedSDGs.includes(9);
      this.a10 = this.item.achievedSDGs.includes(10);
      this.a11 = this.item.achievedSDGs.includes(11);
      this.a12 = this.item.achievedSDGs.includes(12);
      this.a13 = this.item.achievedSDGs.includes(13);
      this.a14 = this.item.achievedSDGs.includes(14);
      this.a15 = this.item.achievedSDGs.includes(15);
      this.a16 = this.item.achievedSDGs.includes(16);
      this.a17 = this.item.achievedSDGs.includes(17);
    },
    submitFn() {
      this.saving = true;
      let sdg = this.getAchivedSDG();
      let payload = {
        isToggleEnabled : this.isToggleEnabled,
        isToggleEnableCertificate : this.isToggleEnableCertificate,
        profile: this.item._id,
        overallRanking: this.overall,
        overallSDGScore: this.sdg,
        achievedSDGs: sdg,
      };
      countryApi.setSdg(payload).then((res) => {
        console.log(res)
        let payload2 = {
          snackBarText: `The country '${this.item.name}' has been Updated successfully`,
          snackBarColor: "secondary",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.saving = false;
        this.topicSubTopicDialog = false;
        this.$emit("initFN");
      }, error => {
        console.log(error)
        this.saving = false;
        this.$emit("initFN");
      });
    },
    getAchivedSDG() {
      let selected = [];
      if (this.a1) {
        selected.push(1);
      }
      if (this.a2) {
        selected.push(2);
      }
      if (this.a3) {
        selected.push(3);
      }
      if (this.a4) {
        selected.push(4);
      }
      if (this.a5) {
        selected.push(5);
      }
      if (this.a6) {
        selected.push(6);
      }
      if (this.a7) {
        selected.push(7);
      }
      if (this.a8) {
        selected.push(8);
      }
      if (this.a9) {
        selected.push(9);
      }
      if (this.a10) {
        selected.push(10);
      }
      if (this.a11) {
        selected.push(11);
      }
      if (this.a12) {
        selected.push(12);
      }
      if (this.a13) {
        selected.push(13);
      }
      if (this.a14) {
        selected.push(14);
      }
      if (this.a15) {
        selected.push(15);
      }
      if (this.a16) {
        selected.push(16);
      }
      if (this.a17) {
        selected.push(17);
      }
      return selected;
    }
  },
};
</script>

<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>
